<template>
  <div class="main">
    <SellStockProducts
      :orders-articles="ordersArticles"
      @sendArticle="setArticleNumber"
      @resetAllMateriales="resetAllMateriale"
    />
    <SellFromStockLocation
      :article-number="articleNumber"
      @addToOrder="addOrder"
    />
  </div>
</template>

<script>
import SellStockProducts from '@/components/orders/SellStockProducts.vue'
import SellFromStockLocation from '@/components/orders/SellFromStockLocation.vue'

export default {
  components: { SellStockProducts, SellFromStockLocation },
  data() {
    return {
      articleNumber: '',
      ordersArticles: [],
    }
  },
  methods: {
    setArticleNumber(item) {
      this.articleNumber = item
    },
    addOrder(value) {
      this.ordersArticles.push(value)
    },
    resetAllMateriale() {
      this.ordersArticles = []
    },
  },
}
</script>

<style scoped>
.main {
  display: flex;
  justify-content: flex-start !important;
  gap: 10px;
}
@media screen and (max-width: 1200px) {
  .main {
    padding: 70px 15px 15px 20px;
    display: flex;
    flex-direction: column;
  }
}
</style>
